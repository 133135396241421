import cookie from 'cookie'
import { navigate } from 'gatsby'
import React from 'react'
import SEO from '../components/seo'
import loginImage from '../images/icons/login.svg'

class Login extends React.Component {
  handleLogin = () => {
    window.location.replace('/.netlify/functions/getLogin/login')
  }

  render() {
    if (typeof window !== 'undefined') {
      const { profile } = document && cookie.parse(document.cookie)

      if (!!profile) {
        navigate('/')
        return null
      }
    }

    return (
      <>
        <SEO title="Login" />
        <section className="ef-section">
          <div className="ef-container">
            <div className="ef-row -x-center">
              <div className="ef-col -m-6 u-text-center">
                <img
                  className="u-mb-xl"
                  src={loginImage}
                  alt="Login"
                  style={{ maxWidth: '180px' }}
                />

                <h3>Hi there!</h3>
                <p className="ef-text-subtitle u-mb-xl">
                  Please login with your EF account to access gud.ef.design
                </p>
                <button
                  className="ef-button -secondary -dark"
                  onClick={() => this.handleLogin()}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Login
